<template>
  <div class="konaUpload-image">
        <KonaUpload
          :placeholder="lang.botMaker.addBot.widget.onlyImage[languageSelected]"
          :key="keyImage"
          :accept="acceptedMediaTypes"
          @onChange="updateFile"
          ref="konaUpload" 
          :defaultFile="savedFile"   
        />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { MEDIA_TYPES } from '@/constants/constants'

export default {
  props: {
    savedFile: {
      type: Object, // { name: "", fileData: "", fileUrl: "", "fileFormat": "" }
      default: null
    }
  },
  data() {
    return {
      keyImage: new Date().getTime(),
      showPreview: true
    }
  },
  components: {
    KonaUpload: () => import('@/components/KonaUpload.vue'),
  },
  methods: {
    updateFile(newVal) {
      this.showPreview = false
      this.$emit('onChange', newVal)
    }
  },
  computed: {
    acceptedMediaTypes() {
      return MEDIA_TYPES.join(',')
    },
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>
